/* You can add global styles to this file, and also import other style files */

.loader-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
    border-radius: 2px;
}

.loading-circle {
    display: inline-block;
    border-radius: 100px;
    width: 4px;
    height: 4px;
    margin: 2px;
    opacity: 0.4;
    background-color: var(--ui-color-primary);

    &.first {
        animation: updown 1s infinite;
    }

    &.middle {
        animation: updown 1s infinite;
        animation-delay: 0.2s;
    }

    &.last {
        animation: updown 1s infinite;
        animation-delay: 0.4s;
    }
}

@keyframes updown {
    0% {
        opacity: 0.4;
        transform: translateY(0);
    }

    16.5% {
        opacity: 1;
        transform: translateY(-3px);
    }

    33% {
        opacity: 0.4;
        transform: translateY(0px);
    }
}

html,
body {
    display: block;
    height: 100%;
    width: 100%;
}
